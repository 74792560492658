import axios from '@/utils/request'

// 手机号登录
export const login = (data) => {
  return axios.request({
    url: '/api/login/telephone',
    data,
    method: 'get'
  })
}

// 手机号登录
export const loginNew = (data) => {
  return axios.request({
    url: '/api/h5-enterprise/login/telephone',
    data,
    method: 'post'
  })
}
// 账号密码登录
export const passwordLogin = (data) => {
  return axios.request({
    url: '/api/h5-enterprise/login/password',
    data,
    method: 'post'
  })
}


// 获取手机验证码
export const getCode = (data) => {
  return axios.request({
    url: '/api/login/smsCode',
    data,
    method: 'get'
  })
}

// 退出登录
export function logout() {
  return axios.request({
    url: '/api/logout',
    method: 'post'
  })
}

// 用户学分明细
export const userScoreLog = (data) => {
  return axios.request({
    url: '/api/userScoreLog/page',
    data,
    method: 'get'
  })
}

//我的分享
export const myArticleList = (data) => {
  return axios.request({
    url: '/api/article/myActicle/list',
    data,
    method: 'get'
  })
}

// 讲师申请
export const teacherApply = (data) => {
  return axios.request({
    url: '/api/teacher/add',
    data,
    method: 'post'
  })
}

// 上传
export const upload = (data) => {
  return axios.request({
    url: '/api/sys/common/upload',
    data,
    method: 'post'
  })
}

// 证书
export const certificateList = (data) => {
  return axios.request({
    url: '/api/certificate/list',
    data,
    method: 'get'
  })
}
// 证书新
export const getCertificateList = (data) => {
  return axios.request({
    url: '/api/certificate/personList',
    data,
    method: 'get'
  })
}

export const queryByIdcertificate = (data) => {
  return axios.request({
    url: '/api/certificate/queryById',
    data,
    method: 'get'
  })
}

// 生成证书
export const getUserCertificateImg = (data) => {
  return axios.request({
    url: '/api/certificate/getUserCertificateImg',
    data,
    method: 'get'
  })
}

// 重新生成证书
export const updateCertificateImg = (data) => {
  return axios.request({
    url: '/api/certificate/createUserCertificateImg',
    data,
    method: 'get'
  })
}

//观看历史
export const learningTaskSubTaskUserStudyLogList = (data) => {
  return axios.request({
    url: '/api/learningTaskSubTaskUserStudyLog/list',
    data,
    method: 'get'
  })
}

// 获取学习路径以及学习任务
export const learningTaskPathAllList = (data) => {
  return axios.request({
    url: '/api/learningTaskPath/all/taskPath/progress',
    data,
    method: 'get'
  })
}

// 获取用户信息
export const getUserData = (data) => {
  return axios.request({
    url: '/api/basicInfo',
    data,
    method: 'get'
  })
}

// 获取所属部门数据
export const getDepartment = (data) => {
  return axios.request({
    url: '/api/belongToDeparts',
    data,
    method: 'get'
  })
}

// /api/login/getPCQrconnetUrl
export const getPCQrconnetUrl = (data) => {
  return axios.request({
    url: '/api/login/getPCQrconnetUrl',
    data,
    method: 'get'
  })
}
// 获取titck
export const getTicket = (data) => {
  return axios.request({
    url: '/api/login/pcQrconnetLogin',
    data,
    method: 'get'
  })
}

// 通过企微code获取token
export const getToken = (data) => {
  return axios.request({
    url: 'api/wechat-enterprise/loginByCode',
    data,
    method: 'get'
  })
}

// 重新生成完成率统计
export const refreshComplete = (data) => {
  return axios.request({
    url: '/api/userLearningTask/reCalculate/completeRatio',
    data,
    method: 'get'
  })
}
